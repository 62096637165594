import React from 'react'
import { useFormikContext } from 'formik'
import {
  IKompletteraFormValues,
  IKomplettering,
} from '@local/Views/TidigareSysselsattningar/KompletteraForm/KompletteraForm.types'
import {
  getKompletteringErrors,
  getKompletteringTouched,
} from '@local/Utils/Helpers/TidigareSysselsattningar.helpers'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { ITidigareSysselsattningarContent } from '@local/Views/TidigareSysselsattningar/TidigareSysselsattningar.types'
import { TidigareSysselsattningar } from '@local/Common.types'
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import Gutter from '@local/Components/Gutter'
import { isNil } from 'ramda'
import FormErrorMessage from '@local/Components/FormErrorMessage'

const TypAvSysselsattning = ({
  index,
  komplettering,
}: {
  index: number
  komplettering: IKomplettering
}): JSX.Element => {
  const { errors, touched, handleChange } =
    useFormikContext<IKompletteraFormValues>()

  const {
    formular: {
      typAvSysselsattning: typAvSysselsattningContent,
      tidigareSysselsattningar: tidigareSysselsattningarContent,
    },
  } = usePickEpiContent<ITidigareSysselsattningarContent>()

  const kompletteringErrors = getKompletteringErrors(index, errors)
  const kompletteringTouched = getKompletteringTouched(index, touched)

  return (
    <>
      <Typography variant="h6">{typAvSysselsattningContent.heading}</Typography>

      <Gutter offset_xs={16} />

      <Grid container>
        <Grid item xs md={6}>
          <FormControl
            error={
              kompletteringTouched.sysselsattningstyp &&
              !isNil(kompletteringErrors.sysselsattningstyp)
            }
            fullWidth
          >
            <InputLabel id={`kompletteringar.${index}.sysselsattningstypLabel`}>
              {typAvSysselsattningContent.dropdownLabel}
            </InputLabel>

            <Select
              defaultValue=""
              name={`kompletteringar.${index}.sysselsattningstyp`}
              id={`kompletteringar.${index}.sysselsattningstyp`}
              labelId={`kompletteringar.${index}.sysselsattningstypLabel`}
              value={komplettering.sysselsattningstyp ?? ''}
              label={typAvSysselsattningContent.dropdownLabel}
              onChange={handleChange}
            >
              {Object.values(TidigareSysselsattningar).map((sysselsattning) => (
                <MenuItem key={sysselsattning} value={sysselsattning}>
                  {tidigareSysselsattningarContent[sysselsattning]}
                </MenuItem>
              ))}
            </Select>

            {kompletteringTouched.sysselsattningstyp &&
              !isNil(kompletteringErrors.sysselsattningstyp) && (
                <FormErrorMessage>
                  {kompletteringErrors.sysselsattningstyp}
                </FormErrorMessage>
              )}
          </FormControl>
        </Grid>
      </Grid>
    </>
  )
}

export default TypAvSysselsattning
