import {
  IAnsokanDetailsContent,
  IAnsokanItem,
} from '@local/Views/AnsokanDetails/AnsokanDetails.types'
import React from 'react'
import { useIsTIA, usePickEpiContent } from '@local/Utils/Hooks'
import Uppercase from '@local/Components/Uppercase'
import {
  CardSectionContent,
  CardSectionValuePair,
} from '@local/Views/AnsokanDetails/AnsokanDetailsForm/Helpers/styledComponents'
import { Grid, Typography } from '@mui/material'

const Arbetslivserfarenhet = ({
  ansokan,
}: {
  ansokan: IAnsokanItem
}): JSX.Element => {
  const { arbetslivserfarenhet } = usePickEpiContent<IAnsokanDetailsContent>()
  const isTia = useIsTIA()

  return (
    <div data-testid="arbetslivserfarenhet">
      <Uppercase variant="h6">{arbetslivserfarenhet.heading}</Uppercase>

      <CardSectionContent>
        <Grid container>
          {isTia && (
            <Grid item xs={12} sm={6} md={4}>
              <CardSectionValuePair>
                <Typography variant="subtitle1">
                  {arbetslivserfarenhet.arbetstimmar}
                </Typography>
                <Typography variant="subtitle2">
                  {ansokan.anstallningsUppgifter.arbetstimmarPerVecka >= 16
                    ? arbetslivserfarenhet.timmarMerTia
                    : arbetslivserfarenhet.timmarMindreTia}
                </Typography>
              </CardSectionValuePair>
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={4}>
            <CardSectionValuePair>
              <Typography variant="subtitle1">
                {arbetslivserfarenhet.etableringsvillkor}
              </Typography>
              <Typography variant="subtitle2">
                {ansokan.hasIntygatEtableringsvillkor
                  ? arbetslivserfarenhet.ja
                  : arbetslivserfarenhet.nej}
              </Typography>
            </CardSectionValuePair>
          </Grid>
        </Grid>
      </CardSectionContent>
    </div>
  )
}

export default Arbetslivserfarenhet
