import React from 'react'
import { HTMLMapper as HTMLMapperTRR, ElementMap } from '@trr/html-mapper'
import { Typography, styled } from '@mui/material'
import getConfig from '@local/Utils/getConfig'

const StyledHTMLMapper = styled('div')(({ theme }) => ({
  ul: {
    marginBottom: 0,

    '&:not(:last-child)': {
      marginBottom: theme.spacing(5),
    },
  },
  'article[class*="MuiBox-root"]': {
    maxWidth: 752,
  },
  'a > svg[data-testid="ChevronRightIcon"]': {
    display: 'none',
  },
}))

const allowedTags = ['p']

const elements: ElementMap = {
  p: ({ renderChildren }) => (
    <Typography variant="body1" gutterBottom={false}>
      {renderChildren()}
    </Typography>
  ),
}

const HTMLMapper = ({ body }: { body: string }) => {
  const { MEDIA_URL } = getConfig()

  return (
    <StyledHTMLMapper>
      <HTMLMapperTRR
        body={body}
        elements={elements}
        allowedTags={allowedTags}
        mediaUrl={MEDIA_URL}
      />
    </StyledHTMLMapper>
  )
}

export default HTMLMapper
