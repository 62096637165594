import React from 'react'
import { TidigareSysselsattningar } from '@local/Common.types'
import { IKomplettering } from '@local/Views/TidigareSysselsattningar/KompletteraForm/KompletteraForm.types'
import OvrigSysselsattning from '@local/Views/TidigareSysselsattningar/KompletteraForm/Komplettering/Sysselsattningar/OvrigSysselsattning'
import Provanstallning from '@local/Views/TidigareSysselsattningar/KompletteraForm/Komplettering/Sysselsattningar/Provanstallning'
import TidsbegransadAnstallning from '@local/Views/TidigareSysselsattningar/KompletteraForm/Komplettering/Sysselsattningar/TidsbegransadAnstallning'
import Tillsvidareanstallning from '@local/Views/TidigareSysselsattningar/KompletteraForm/Komplettering/Sysselsattningar/Tillsvidareanstallning'

const useGetSysselsattningForm = (
  komplettering: IKomplettering,
  index: number
) => {
  switch (komplettering.sysselsattningstyp) {
    case TidigareSysselsattningar.Provanstallning:
      return <Provanstallning index={index} komplettering={komplettering} />
    case TidigareSysselsattningar.Tillsvidareanstallning:
      return (
        <Tillsvidareanstallning index={index} komplettering={komplettering} />
      )
    case TidigareSysselsattningar.TidsbegransadAnstallning:
      return (
        <TidsbegransadAnstallning index={index} komplettering={komplettering} />
      )
    default:
      if (komplettering.sysselsattningstyp)
        return (
          <OvrigSysselsattning index={index} komplettering={komplettering} />
        )
  }
}

export default useGetSysselsattningForm
