import React from 'react'
import { Grid, Typography } from '@mui/material'
import Gutter from '@local/Components/Gutter'
import { useGetPreambleContent } from '@local/Utils/Hooks/TidigareSysselsattningar'

const Preamble = (): JSX.Element => {
  const preambleContent = useGetPreambleContent()

  return (
    <Grid container>
      <Grid item md={8}>
        <Typography variant="preamble">{preambleContent.preamble}</Typography>

        <Gutter offset_xs={40} />
      </Grid>

      <Grid item md={8}>
        {preambleContent.element}
      </Grid>
    </Grid>
  )
}

export default Preamble
