import React, { useEffect, useState } from 'react'
import { isEmpty } from 'ramda'
import {
  IAnsokanDetailsContent,
  IAnsokanItem,
} from '@local/Views/AnsokanDetails/AnsokanDetails.types'
import {
  useAnsokanNeedsGranskning,
  usePickEpiContent,
} from '@local/Utils/Hooks'
import { IAnsokanDetailsFormValues } from '@local/Views/AnsokanDetails/AnsokanDetailsForm/AnsokanDetailsForm.types'
import { FormikTouched, setNestedObjectValues, useFormikContext } from 'formik'
import { Button, Grid } from '@mui/material'
import FormErrorMessage from '@local/Components/FormErrorMessage'

import GodkannAnsokanModal from './GodkannAnsokanModal'

const GodkannAnsokan = ({
  ansokan,
}: {
  ansokan: IAnsokanItem
}): JSX.Element => {
  const { validateForm, setTouched, errors } =
    useFormikContext<IAnsokanDetailsFormValues>()

  const [shouldShowErrorMessage, setShouldShowErrorMessage] = useState(false)
  const { godkannAnsokan, etableringsvillkor } =
    usePickEpiContent<IAnsokanDetailsContent>()
  const [modalOpen, setModalOpen] = React.useState(false)
  const [shouldResetModalState, setShouldResetModalState] =
    React.useState(false)

  useEffect(() => {
    if (isEmpty(errors)) {
      setShouldShowErrorMessage(false)
    }
  }, [errors])

  const openModal = () => {
    setShouldResetModalState(true)
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
    setShouldResetModalState(false)
  }

  const handleGodkann = () => {
    validateForm()
      .then((validationErrors) => {
        if (!isEmpty(validationErrors)) {
          setTouched(
            setNestedObjectValues<FormikTouched<IAnsokanDetailsFormValues>>(
              validationErrors,
              true
            )
          ).catch((err) => console.log(err))
          setShouldShowErrorMessage(true)
        } else {
          openModal()
        }
      })
      .catch((e) => console.error(e))
  }

  const needsGranskning = useAnsokanNeedsGranskning(ansokan)

  return (
    needsGranskning && (
      <>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Button onClick={handleGodkann}>{godkannAnsokan.heading}</Button>
          </Grid>

          {shouldShowErrorMessage && (
            <Grid item sm>
              <FormErrorMessage>
                {etableringsvillkor.etableringsvillkor.varning}
              </FormErrorMessage>
            </Grid>
          )}
        </Grid>

        <GodkannAnsokanModal
          type={ansokan.type}
          closeModal={closeModal}
          modalOpen={modalOpen}
          shouldResetModalState={shouldResetModalState}
        />
      </>
    )
  )
}

export default GodkannAnsokan
