import React from 'react'
import { IFackligKontaktPerson } from '@local/Common.types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { IAnsokanDetailsContent } from '@local/Views/AnsokanDetails/AnsokanDetails.types'
import Uppercase from '@local/Components/Uppercase'
import { Grid, Typography } from '@mui/material'
import Gutter from '@local/Components/Gutter'

const FackligaKontaktpersoner = ({
  fackligaKontaktPersoner,
}: {
  fackligaKontaktPersoner: IFackligKontaktPerson[]
}): JSX.Element => {
  const { fackligaKontaktpersoner: content } =
    usePickEpiContent<IAnsokanDetailsContent>()

  const getFormattedName = (fackligKontaktperson: IFackligKontaktPerson) =>
    `${fackligKontaktperson.fornamn} ${fackligKontaktperson.efternamn}`

  return (
    <div data-testid="fackliga-kontaktpersoner">
      <Uppercase variant="h6">{content.heading}</Uppercase>

      <Gutter offset_xs={32} />

      {fackligaKontaktPersoner.map((fackligKontaktperson, index) => (
        <Grid
          container
          key={`${fackligKontaktperson.fornamn} ${fackligKontaktperson.efternamn}`}
        >
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">
              {getFormattedName(fackligKontaktperson)}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2">
              {fackligKontaktperson.fackforbund}
            </Typography>
          </Grid>

          {fackligaKontaktPersoner.length - 1 !== index && (
            <Gutter offset_xs={32} />
          )}
        </Grid>
      ))}
    </div>
  )
}

export default FackligaKontaktpersoner
