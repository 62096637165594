import React from 'react'
import {
  Autocomplete as MuiAutoComplete,
  InputAdornment,
  TextField,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import { ISelectOption, ISelectOptionFormikWrapper } from '@local/Common.types'

export interface IAutocompleteProps {
  label?: string
  placeholder?: string
  formikName: string
  value: ISelectOptionFormikWrapper
  isLoading: boolean
  isError: boolean
  errorMsg: string
  selectOptions: ISelectOption[]
  onChange: (value: ISelectOptionFormikWrapper) => void
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
}

const Autocomplete = ({
  label,
  placeholder,
  formikName,
  value,
  isLoading,
  isError,
  errorMsg,
  selectOptions,
  onChange,
  setSearchTerm,
}: IAutocompleteProps): JSX.Element => {
  const handleChange = (_: unknown, value: ISelectOption) => {
    if (value) {
      onChange({ ...value, label: String(value.value) })
    }
  }

  return (
    <MuiAutoComplete
      fullWidth
      filterOptions={(x) => x}
      onInputChange={(_, value) => setSearchTerm(value)}
      onChange={handleChange}
      options={selectOptions}
      isOptionEqualToValue={(option, value) =>
        (option as ISelectOption).value === (value as ISelectOption).value
      }
      id={formikName}
      value={value}
      loading={isLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label={label}
          error={isError}
          helperText={errorMsg}
          placeholder={placeholder ?? null}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
      renderOption={(props, option, { inputValue }) => {
        const value = (option as ISelectOption).value as string
        const id = (option as ISelectOption).id

        const matches = match(value, inputValue, {
          insideWords: true,
        })

        const parts = parse(value, matches)

        return (
          <li {...props} key={`${value}_${id}`}>
            <div>
              {parts.map((part) => (
                <span
                  key={part.text}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        )
      }}
    />
  )
}

export default Autocomplete
