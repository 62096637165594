import React from 'react'
import OrsakTillUppsagning from '@local/Views/TidigareSysselsattningar/KompletteraForm/Komplettering/OrsakTillUppsagning'
import Foretagsuppgifter from '@local/Views/TidigareSysselsattningar/KompletteraForm/Komplettering/Foretagsuppgifter'
import Anstallningsuppgifter from '@local/Views/TidigareSysselsattningar/KompletteraForm/Komplettering/Anstallningsuppgifter'
import Tjansteman from '@local/Views/TidigareSysselsattningar/KompletteraForm/Komplettering/Tjansteman/Tjansteman'
import Arbetsgivarintyg from '@local/Views/TidigareSysselsattningar/KompletteraForm/Komplettering/Arbetsgivarintyg'
import {
  IKomplettering,
  OrsakerTillUppsagning,
} from '@local/Views/TidigareSysselsattningar/KompletteraForm/KompletteraForm.types'
import IsVdEllerUndantagsgrupp from '@local/Views/TidigareSysselsattningar/KompletteraForm/Komplettering/IsVdEllerUndantagsgrupp'
import Gutter from '@local/Components/Gutter'

const Tillsvidareanstallning = ({
  index,
  komplettering,
}: {
  index: number
  komplettering: IKomplettering
}): JSX.Element => (
  <>
    <Gutter offset_xs={32} offset_md={40} />

    <OrsakTillUppsagning index={index} />

    <Gutter offset_xs={32} offset_md={40} />

    <Foretagsuppgifter index={index} />

    <Gutter offset_xs={32} offset_md={40} />

    <Anstallningsuppgifter komplettering={komplettering} index={index} />

    <Gutter offset_xs={32} offset_md={40} />

    <Tjansteman index={index} />

    <Gutter offset_xs={32} offset_md={40} />

    <IsVdEllerUndantagsgrupp index={index} />

    {komplettering.orsakTillUppsagning !== OrsakerTillUppsagning.Konkurs && (
      <>
        <Gutter offset_xs={32} offset_md={40} />

        <Arbetsgivarintyg komplettering={komplettering} index={index} />
      </>
    )}
  </>
)

export default Tillsvidareanstallning
