import React from 'react'
import Gutter from '@local/Components/Gutter'
import { IAnsokanListSection } from '@local/Views/DinaAnsokningar/AnsokanListSection/Types'
import { Grid, Typography } from '@mui/material'
import AnsokanList from '@local/Views/DinaAnsokningar/AnsokanList/AnsokanList'

const AnsokanListSection = ({
  title,
  preamble,
  ansokningar,
  ariaLabel,
}: IAnsokanListSection): JSX.Element => (
  <>
    <Gutter offset_xs={32} />

    <Typography variant="h5" gutterBottom>
      {title}
    </Typography>

    {preamble ? (
      <Grid container>
        <Grid item xs md={8}>
          <Typography variant="preamble">{preamble}</Typography>
          <Gutter offset_xs={32} />
        </Grid>
      </Grid>
    ) : (
      <Gutter offset_xs={24} />
    )}

    <AnsokanList ansokningar={ansokningar} ariaLabel={ariaLabel} />

    <Gutter offset_xs={40} />
  </>
)

export default AnsokanListSection
