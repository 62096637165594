import React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { AppBar, Grid, Toolbar, ToolbarProps } from '@mui/material'
import { IStickyFooter } from '@local/Components/StickyFooter/types'
import FormErrorMessage from '@local/Components/FormErrorMessage'
import { useIsSmallScreensOrAbove } from '@local/Utils/Hooks'

const StyledToolbar = styled(({ ...props }: ToolbarProps) => (
  <Toolbar {...props} />
))(({ theme }) => ({
  '&.MuiToolbar-root': {
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing(2)} 0`,

    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(3)} 0`,
    },
    '& .MuiGrid-item > *': {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 'unset',
      },
    },

    '& .MuiGrid-item: first-child > *': {
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        marginBottom: 0,
      },
    },
  },
}))

const StickyFooter = ({
  leftElements,
  rightElements,
  isError = false,
  errorMsg,
}: IStickyFooter): JSX.Element => {
  const theme = useTheme()
  const isSmallScreensOrAbove = useIsSmallScreensOrAbove()

  return (
    <AppBar
      position="sticky"
      color="transparent"
      sx={{
        top: 'auto',
        bottom: 0,
        backgroundColor: theme.palette.common.white,
      }}
      elevation={0}
    >
      <StyledToolbar disableGutters>
        <Grid container>
          <Grid item xs={12} sm order={isSmallScreensOrAbove ? 0 : 1}>
            {leftElements}
          </Grid>
          {isError && (
            <Grid
              item
              xs={12}
              sm="auto"
              order={isSmallScreensOrAbove ? 1 : 0}
              sx={{
                display: 'flex',
                marginRight: theme.spacing(1),
                marginBottom: isSmallScreensOrAbove ? 0 : theme.spacing(1),
              }}
              alignItems="center"
            >
              <FormErrorMessage>{errorMsg}</FormErrorMessage>
            </Grid>
          )}
          <Grid item xs={12} sm="auto" order={2}>
            {rightElements}
          </Grid>
        </Grid>
      </StyledToolbar>
    </AppBar>
  )
}

export default StickyFooter
