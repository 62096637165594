import React from 'react'
import { IUniqueFile } from '@local/Components/FileUpload/FileUpload.types'
import FileUpload from '@local/Components/FileUpload'
import {
  IArbetsgivarintygProps,
  IKompletteraFormValues,
} from '@local/Views/TidigareSysselsattningar/KompletteraForm/KompletteraForm.types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { ITidigareSysselsattningarContent } from '@local/Views/TidigareSysselsattningar/TidigareSysselsattningar.types'
import {
  useDeleteBilagaMutation,
  useUploadBilagaMutation,
} from '@local/services/API/tidigareSysselsattningarApi'
import {
  getKompletteringErrors,
  getKompletteringTouched,
  isOvrigSysselsattning,
} from '@local/Utils/Helpers/TidigareSysselsattningar.helpers'
import { useFormikContext } from 'formik'
import { generateErrorMessage } from '@local/Utils/Helpers/Forms/Forms.helpers'
import { useAuthentication } from '@trr/app-shell-data'

const Arbetsgivarintyg = ({
  komplettering: { id: kompletteringFormId, sysselsattningstyp },
  index,
}: IArbetsgivarintygProps): JSX.Element => {
  const { sub: klientId } = useAuthentication()
  const {
    formular: { arbetsgivarintyg: arbetsgivarintygContent, laddaUppBilaga },
  } = usePickEpiContent<ITidigareSysselsattningarContent>()

  const { errors, touched, setFieldValue, values } =
    useFormikContext<IKompletteraFormValues>()

  const kompletteringErrors = getKompletteringErrors(index, errors)
  const kompletteringTouched = getKompletteringTouched(index, touched)

  const [uploadBilaga, { isLoading: isLoadingUpload }] =
    useUploadBilagaMutation()

  const [deleteBilaga] = useDeleteBilagaMutation()

  const uploadHandler = async (
    file: IUniqueFile,
    fileStream: File
  ): Promise<void> => {
    const formData = new FormData()
    formData.append('kompletteringFormId', kompletteringFormId)
    formData.append('fileId', file.id)
    formData.append('file', fileStream)

    return uploadBilaga({ formData, userSub: klientId }).unwrap()
  }

  const deleteHandler = (file: IUniqueFile) =>
    deleteBilaga({ bilagaId: file.id, userSub: klientId }).unwrap()

  const heading = isOvrigSysselsattning(sysselsattningstyp)
    ? arbetsgivarintygContent.headingOvrigSysselsattning
    : arbetsgivarintygContent.heading

  return (
    <FileUpload
      isLoadingUpload={isLoadingUpload}
      content={{
        ...laddaUppBilaga,
        heading: heading,
        uploadButtonText: arbetsgivarintygContent.uploadFileButtonText,
      }}
      inputName={`kompletteringar.${index}.arbetsgivarintyg`}
      files={values.kompletteringar[index].arbetsgivarintyg}
      setFieldValue={setFieldValue}
      errorMessage={generateErrorMessage({
        touched: kompletteringTouched.arbetsgivarintyg !== undefined,
        errorMsg: kompletteringErrors.arbetsgivarintyg as string,
      })}
      filePickedHandler={uploadHandler}
      fileDeletedHandler={deleteHandler}
      multiple
    />
  )
}

export default Arbetsgivarintyg
