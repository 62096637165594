import React from 'react'
import { usePickEpiContent } from '@local/Utils/Hooks'
import {
  IAnsokanDetailsContent,
  IAnsokanItem,
} from '@local/Views/AnsokanDetails/AnsokanDetails.types'
import { Status } from '@local/Common.types'
import HTMLMapper from '@local/Components/HTMLMapper'
import { Grid } from '@mui/material'

const FelaktigaUppgifter = ({
  ansokan,
}: {
  ansokan: IAnsokanItem
}): JSX.Element => {
  const { felaktigaUppgifter } = usePickEpiContent<IAnsokanDetailsContent>()

  if (!ansokan.isAktuellAnsokan || ansokan.status !== Status.NeedsGranskning) {
    return null
  }

  return (
    <Grid container>
      <Grid item xs md={8} lg={6}>
        <HTMLMapper body={felaktigaUppgifter.mainBody} />
      </Grid>
    </Grid>
  )
}

export default FelaktigaUppgifter
