import React from 'react'
import { usePickEpiContent } from '@local/Utils/Hooks'
import AnsokanDetailsForm from '@local/Views/AnsokanDetails/AnsokanDetailsForm'
import { IAnsokanDetailsContent } from '@local/Views/AnsokanDetails/AnsokanDetails.types'
import useGetAnsokan from '@local/Views/AnsokanDetails/useGetAnsokan'
import Spinner from '@local/Components/Spinner'
import Alert from '@local/Components/Alert'

const AnsokanDetails = (): JSX.Element => {
  const { data, isLoading, isError } = useGetAnsokan()
  const content = usePickEpiContent<IAnsokanDetailsContent>()

  if (isLoading) {
    return <Spinner centered />
  }

  if (isError) {
    return <Alert severity="error">{content.nagotGickFel.heading}</Alert>
  }

  return <AnsokanDetailsForm content={content} ansokan={data} />
}

export default AnsokanDetails
