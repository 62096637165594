import React from 'react'
import {
  getStatusColorAndIcon,
  StyledChipIcon,
} from '@local/Utils/Helpers/Ansokan.helpers'
import { IStatusContent, Status } from '@local/Common.types'
import { Chip } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledStatusChip = styled('div')(({ theme }) => ({
  userSelect: 'none',
  pointerEvents: 'none',
  cursor: 'pointer',

  '&:nth-child(2)': {
    marginLeft: theme.spacing(1),
  },
}))

const StatusChip = ({
  status,
  size = 'medium',
  statusContent,
}: {
  status: Status
  statusContent: IStatusContent
  size?: 'medium' | 'small'
}): JSX.Element => {
  const statusStyles = getStatusColorAndIcon(status)

  return (
    <StyledStatusChip>
      <Chip
        icon={
          <StyledChipIcon
            variant={statusStyles.color}
            icon={statusStyles.icon}
          />
        }
        size={size}
        color={statusStyles.color}
        label={statusContent[status] as string}
      />
    </StyledStatusChip>
  )
}

export default StatusChip
