import React from 'react'
import { useIsTIOTB, usePickEpiContent } from '@local/Utils/Hooks'
import { formatCurrencySEK, formatPercentageNumber } from '@local/Utils/Helpers'
import { trrFormat } from '@local/Utils/Helpers/formatDate'
import {
  IAnsokanDetailsContent,
  ILoneuppgifterProps,
} from '@local/Views/AnsokanDetails/AnsokanDetails.types'
import Uppercase from '@local/Components/Uppercase'
import {
  CardSectionContent,
  CardSectionValuePair,
} from '@local/Views/AnsokanDetails/AnsokanDetailsForm/Helpers/styledComponents'
import { Grid, Typography } from '@mui/material'
import Tooltip from '@local/Components/Tooltip'
import HTMLMapper from '@local/Components/HTMLMapper'

const Loneuppgifter = ({ loneUppgifter }: ILoneuppgifterProps): JSX.Element => {
  const { loneuppgifter, ...content } =
    usePickEpiContent<IAnsokanDetailsContent>()

  const isTB = useIsTIOTB()

  return (
    <div data-testid="loneuppgifter">
      <Uppercase variant="h6">{loneuppgifter.heading}</Uppercase>

      <CardSectionContent>
        <Grid container>
          <Grid item xs={12} sm={6} md={4}>
            <CardSectionValuePair>
              <Typography variant="subtitle1">
                {loneuppgifter.manadsLon}
              </Typography>
              <Typography variant="subtitle2">
                {formatCurrencySEK(loneUppgifter.manadslon)}
              </Typography>
            </CardSectionValuePair>
          </Grid>
          {!isTB && (
            <Grid item xs={12} sm={6} md={4}>
              <CardSectionValuePair>
                <Typography variant="subtitle1">
                  {loneuppgifter.lonSenastAndrad}
                </Typography>
                <Typography variant="subtitle2">
                  {trrFormat(loneUppgifter.lonerevisionsdatum)}
                </Typography>
              </CardSectionValuePair>
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={4}>
            <CardSectionValuePair>
              <Typography variant="subtitle1">
                {loneuppgifter.sjukersattningProcent}
              </Typography>
              <Typography variant="subtitle2">
                {formatPercentageNumber(loneUppgifter.sjukersattningsgrad)}
              </Typography>
            </CardSectionValuePair>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CardSectionValuePair>
              <Tooltip
                variant="subtitle1"
                heading={loneuppgifter.provisionTantiemBelopp}
                tooltipContent={
                  <HTMLMapper body={content.tooltipProvisionTantiem.mainBody} />
                }
                gutterBottom={false}
              />
              <Typography variant="subtitle2">
                {formatCurrencySEK(loneUppgifter.provisionBelopp)}
              </Typography>
            </CardSectionValuePair>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CardSectionValuePair>
              <Tooltip
                variant="subtitle1"
                heading={loneuppgifter.skiftJourOBTillaggBelopp}
                tooltipContent={
                  <HTMLMapper body={content.tooltipSkiftJour.mainBody} />
                }
                gutterBottom={false}
              />
              <Typography variant="subtitle2">
                {formatCurrencySEK(loneUppgifter.jourBelopp)}
              </Typography>
            </CardSectionValuePair>
          </Grid>
          {loneUppgifter.kostLogiBelopp > 0 && (
            <Grid item xs={12} sm={6} md={4}>
              <CardSectionValuePair>
                <Typography variant="subtitle1">
                  {loneuppgifter.friKostBostadBelopp}
                </Typography>
                <Typography variant="subtitle2">
                  {formatCurrencySEK(loneUppgifter.kostLogiBelopp)}
                </Typography>
              </CardSectionValuePair>
            </Grid>
          )}

          {!isTB && (
            <Grid item xs={12} sm={6} md={4}>
              <CardSectionValuePair>
                <Tooltip
                  variant="subtitle1"
                  heading={loneuppgifter.avgangsvederlagBelopp}
                  tooltipContent={
                    <HTMLMapper
                      body={content.tooltipAvgangsvederlag.mainBody}
                    />
                  }
                  gutterBottom={false}
                />
                <Typography variant="subtitle2">
                  {formatCurrencySEK(loneUppgifter.avgangsvederlag)}
                </Typography>
              </CardSectionValuePair>
            </Grid>
          )}
        </Grid>
      </CardSectionContent>
    </div>
  )
}

export default Loneuppgifter
