import React from 'react'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { ITidigareSysselsattningarContent } from '@local/Views/TidigareSysselsattningar/TidigareSysselsattningar.types'
import { usePostTidigareSysselsattningarMutation } from '@local/services/API/tidigareSysselsattningarApi'
import Alert from '@local/Components/Alert'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material'
import Gutter from '@local/Components/Gutter'

import { ISkickaInModal } from './SkickaInModal.types'

const SkickaInModal = ({
  modalOpen,
  closeModal,
  submitForm,
}: ISkickaInModal): JSX.Element => {
  const [, { isError, isLoading }] = usePostTidigareSysselsattningarMutation({
    fixedCacheKey: 'shared-komplettera-post',
  })

  const { skickaInModal: skickaInModalContent } =
    usePickEpiContent<ITidigareSysselsattningarContent>()

  return (
    <Dialog onClose={closeModal} open={modalOpen}>
      <DialogTitle>{skickaInModalContent.heading}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1">
            {skickaInModalContent.description}
          </Typography>
        </DialogContentText>

        <Gutter offset_xs={24} />

        {isError && (
          <>
            <Gutter offset_xs={24} />
            <Alert severity="error">{skickaInModalContent.felmeddelande}</Alert>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button disabled={isLoading} variant="text" onClick={closeModal}>
          {skickaInModalContent.avbryt}
        </Button>
        <Button disabled={isLoading} variant="text" onClick={submitForm}>
          {skickaInModalContent.skicka}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SkickaInModal
