import { KlientHub, SignalRRoom } from '@local/App.types'
import { HubConnection, HubConnectionState } from '@microsoft/signalr'
import { useSignalR } from '@trr/app-shell-data'
import { useEffect, useRef, useState } from 'react'

interface ISignalRConnectToRoomProps {
  room: SignalRRoom
  onEventReceived: (params?: unknown) => void
}

interface ISignalRConnectToGroupProps extends ISignalRConnectToRoomProps {
  group: string
}

const connectToGroup = (
  room: SignalRRoom,
  group: string,
  connection: HubConnection,
  onEventReceived: (params?: unknown) => void,
  groupHasBeenAdded: boolean,
  setGroupHasBeenAdded: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (!groupHasBeenAdded) {
    void connection.invoke(KlientHub.AddToGroup, group).then(() => {
      setGroupHasBeenAdded(true)
      connection.on(room, (params) => {
        onEventReceived(params)
      })
    })
  }
}

const connectToRoom = (
  room: SignalRRoom,
  connection: HubConnection,
  onEventReceived: (params?: unknown) => void
) => {
  connection.on(room, (params) => {
    onEventReceived(params)
  })
}

const tryConnect = (
  connection: HubConnection,
  timer: React.MutableRefObject<NodeJS.Timeout>,
  connect: () => void
) => {
  if (timer.current) {
    clearTimeout(timer.current)
  }
  if (connection.state === HubConnectionState.Connected) {
    connect()
  } else if (connection.state === HubConnectionState.Connecting) {
    timer.current = setTimeout(() => {
      tryConnect(connection, timer, connect)
    }, 3000)
  }
}

const useSignalRConnectToGroup = ({
  room,
  group,
  onEventReceived,
}: ISignalRConnectToGroupProps) => {
  const signalR = useSignalR()
  const connection = signalR?.klient as HubConnection
  const timer = useRef<ReturnType<typeof setTimeout>>()
  const [groupHasBeenAdded, setGroupHasBeenAdded] = useState(false)

  useEffect(() => {
    if (connection && group && !groupHasBeenAdded) {
      tryConnect(connection, timer, () => {
        connectToGroup(
          room,
          group,
          connection,
          onEventReceived,
          groupHasBeenAdded,
          setGroupHasBeenAdded
        )
      })
    }
  }, [onEventReceived, connection, room, group, groupHasBeenAdded])

  useEffect(
    () => () => {
      if (connection && groupHasBeenAdded) {
        void connection.invoke(KlientHub.RemoveFromGroup, group).then(() => {
          setGroupHasBeenAdded(false)
          connection.off(room)
        })
      }
    },
    [connection, group, room, groupHasBeenAdded]
  )
}

const useSignalRConnectToRoom = ({
  room,
  onEventReceived,
}: ISignalRConnectToRoomProps) => {
  const signalR = useSignalR()
  const connection = signalR?.klient as HubConnection
  const timer = useRef<ReturnType<typeof setTimeout>>()

  useEffect(() => {
    if (connection) {
      tryConnect(connection, timer, () => {
        connectToRoom(room, connection, onEventReceived)
      })

      return () => {
        connection.off(room)
      }
    }
  }, [onEventReceived, connection, room])

  useEffect(
    () => () => {
      if (connection) {
        connection.off(room)
      }
    },
    [connection, room]
  )
}

export { useSignalRConnectToGroup, useSignalRConnectToRoom }
