import {
  IEditorialBlock,
  ITidigareSysselsattningarEnumContent,
  TidigareSysselsattningar,
} from '@local/Common.types'
import { IKompletteringApiModel } from '@local/Views/TidigareSysselsattningar/KompletteraForm/KompletteraForm.types'

export interface ITidigareSysselsattning {
  id: string
  anstallningsdatum: string
  sistaAnstallningsdatum: string
  foretagsnamn: string
  organisationsnummer: string
  orsakTillUppsagning: string
  skapadDatum: string
  isTjansteman: boolean
  isVdEllerUndantagsgrupp: boolean
  sysselsattningstyp: TidigareSysselsattningar
}

export interface ITidigareSysselsattningarContent {
  heading: string
  preamble: string
  komplettering: IKompletteringContent
  tidigareSysselsattning: ITidigareSysselsattningContent
  tidigareSysselsattningar: ITidigareSysselsattningarEnumContent
  dinKompletteringBehandlas: IEditorialBlock
}

export interface IKompletteringContent {
  buttonTextDesktop: string
  buttonTextMobile: string
  kanKompletteraPreamble: string
  kanInteKompletteraPreamble: string
  kompletteringUnderBearbetningPreamble: string
  kompletteringUnderBearbetningWarningTitle: string
  kompletteringUnderBearbetningWarningMessage: string
}

export interface ITidigareSysselsattningContent {
  inskickad: string
  anstallningsperiod: string
  anstallningsperiodSeparator: string
  ejFaststalltSistaDatum: string
  orsak: string
  tjansteman: string
}

export interface IKompletteraStatusElements {
  preamble: string
  element: JSX.Element
}

export enum KompletteraStatusType {
  KanKomplettera = 'kankomplettera',
  KompletteringUnderBearbetning = 'kompletteringunderbearbetning',
  KanInteKomplettera = 'kanintekomplettera',
}

export interface IPostTidigareSysselsattningarPayloadModel {
  kompletteringar: IKompletteringApiModel[]
  userSub: string
}

export interface IUploadBilagaPayloadModel {
  formData: FormData
  userSub: string
}

export interface IDeleteBilagaPayloadModel {
  bilagaId: string
  userSub: string
}
