import React, { useCallback, useEffect, useRef, useState } from 'react'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { IAnsokanDetailsContent } from '@local/Views/AnsokanDetails/AnsokanDetails.types'
import { matchStringByRegex, UUIDRegex } from '@local/Utils/Helpers/regexes'
import { usePatchForsakraUppsagningSjukdomMutation } from '@local/services/API/ansokanApi'
import { showModal } from '@local/Components/SuccessModal/modalSlice'
import { useAppDispatch } from '@local/Store/configureStore'
import Gutter from '@local/Components/Gutter'
import Alert from '@local/Components/Alert'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import HTMLMapper from '@local/Components/HTMLMapper'
import { decode } from 'html-entities'
import { removeHtmlTags } from '@local/Utils/Helpers'
import ForsakraCheckbox from '@local/Views/AnsokanDetails/AnsokanDetailsForm/ForsakraUppsagningSjukdom/ForsakraUppsagningSjukdomModal/ForsakraCheckbox'
import { useAuthentication } from '@trr/app-shell-data'

const ForsakraUppsagningSjukdomModal = ({
  closeModal,
  modalOpen,
  shouldResetModalState,
}: {
  closeModal: () => void
  modalOpen: boolean
  shouldResetModalState: boolean
}): JSX.Element => {
  const { sub: klientId } = useAuthentication()
  const dispatch = useAppDispatch()
  const currentAnsokanId = matchStringByRegex(
    window.location.pathname,
    UUIDRegex
  )

  const {
    forsakraUppsagningSjukdomJuridiskText,
    forsakraUppsagningSjukdomModal,
    forsakraUppsagningSjukdomSuccess,
    nagotGickFel,
  } = usePickEpiContent<IAnsokanDetailsContent>()

  const [patchForsakraUppsagningSjukdom, { isError, isLoading }] =
    usePatchForsakraUppsagningSjukdomMutation()

  const scrollElement = useRef<HTMLInputElement>(null)

  const scrollToElement = () =>
    scrollElement?.current?.scrollIntoView({
      block: 'nearest',
    })

  const [checked, setChecked] = useState(false)
  const [shouldShowForsakraErrorText, setShouldShowForsakraErrorText] =
    useState(false)

  const handleForsakraUppsagningSjukdom = useCallback(() => {
    if (checked) {
      void (async () => {
        await patchForsakraUppsagningSjukdom({
          ansokanId: currentAnsokanId,
          villkorstext: decode(
            removeHtmlTags(forsakraUppsagningSjukdomJuridiskText.mainBody)
          ),
          userSub: klientId,
        })
          .unwrap()
          .then(() => {
            dispatch(showModal({ content: forsakraUppsagningSjukdomSuccess }))
            closeModal()
          })
      })()
    } else {
      setShouldShowForsakraErrorText(true)
      scrollToElement()
    }
  }, [
    closeModal,
    currentAnsokanId,
    dispatch,
    forsakraUppsagningSjukdomSuccess,
    patchForsakraUppsagningSjukdom,
    checked,
    forsakraUppsagningSjukdomJuridiskText,
    klientId,
  ])

  useEffect(() => {
    if (shouldResetModalState) {
      setShouldShowForsakraErrorText(false)
      setChecked(false)
    }
  }, [shouldResetModalState])

  useEffect(() => {
    if (checked) {
      setShouldShowForsakraErrorText(false)
    }
  }, [checked])

  return (
    <Dialog onClose={closeModal} open={modalOpen}>
      <DialogTitle>{forsakraUppsagningSjukdomModal.heading}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <HTMLMapper body={forsakraUppsagningSjukdomJuridiskText.mainBody} />
        </DialogContentText>

        <Gutter offset_xs={24} />

        <ForsakraCheckbox
          isError={shouldShowForsakraErrorText}
          checked={checked}
          setChecked={setChecked}
          scrollElement={scrollElement}
        />

        {isError && (
          <>
            <Gutter offset_xs={24} />
            <Alert severity="error">{nagotGickFel.heading}</Alert>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button disabled={isLoading} variant="text" onClick={closeModal}>
          {forsakraUppsagningSjukdomModal.cancel}
        </Button>
        <Button
          disabled={isLoading}
          variant="text"
          onClick={handleForsakraUppsagningSjukdom}
        >
          {forsakraUppsagningSjukdomModal.buttonSubmit}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ForsakraUppsagningSjukdomModal
