import React from 'react'
import type { ITidigareSysselsattningarContent } from '@local/Views/TidigareSysselsattningar/TidigareSysselsattningar.types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { useFormikContext } from 'formik'
import { Button } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { IKompletteraFormValues } from '@local/Views/TidigareSysselsattningar/KompletteraForm/KompletteraForm.types'

const KompletteraFormAdd = () => {
  const { laggTillKomplettering } =
    usePickEpiContent<ITidigareSysselsattningarContent>()

  const { values, setValues } = useFormikContext<IKompletteraFormValues>()

  const addForm = () => {
    const kompletteringar = [...values.kompletteringar]

    kompletteringar.push({
      id: crypto.randomUUID(),
      sysselsattningstyp: undefined,
      foretagsnamn: '',
      organisationsnummer: '',
      orsakTillUppsagning: null,
      anstallningStartDatum: null,
      anstallningSlutDatum: null,
      isTjansteman: '',
      isVdEllerUndantagsgrupp: '',
      arbetsgivarintyg: [],
      forsakratUppgifter: false,
    })

    setValues({
      ...values,
      kompletteringar,
    })?.catch((err) => console.log(err))
  }

  return (
    <Button variant="outlined" startIcon={<AddCircleIcon />} onClick={addForm}>
      {laggTillKomplettering.heading}
    </Button>
  )
}

export default KompletteraFormAdd
