import React from 'react'
import { IAnsokanListItem } from '@local/Views/DinaAnsokningar/DinaAnsokningar.types'
import AnsokanListItem from '@local/Views/DinaAnsokningar/AnsokanList/AnsokanListItem'

const AnsokanList = ({
  ansokningar,
  ariaLabel,
}: {
  ansokningar: IAnsokanListItem[]
  ariaLabel: string
}) => (
  <div aria-label={ariaLabel}>
    {ansokningar.map((ansokan) => (
      <AnsokanListItem key={ansokan.id} ansokan={ansokan} />
    ))}
  </div>
)

export default AnsokanList
