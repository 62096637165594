import Gutter from '@local/Components/Gutter'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { IAnsokanDetailsContent } from '@local/Views/AnsokanDetails/AnsokanDetails.types'
import { Grid, Typography } from '@mui/material'
import React from 'react'

interface AnsokanDetailsHeaderProps {
  isEgenAnsokan: boolean
}

export const AnsokanDetailsHeader = ({
  isEgenAnsokan,
}: AnsokanDetailsHeaderProps) => {
  const { titelOchIngress: content } =
    usePickEpiContent<IAnsokanDetailsContent>()

  return (
    <Grid container>
      <Grid item md={8}>
        <Typography variant="h1" gutterBottom>
          {isEgenAnsokan ? content.egenTitel : content.arbetsgivareTitel}
        </Typography>
        <Typography variant="preamble">
          {isEgenAnsokan ? content.egenIngress : content.arbetsgivareIngress}
        </Typography>
      </Grid>
      <Gutter offset_xs={40} />
    </Grid>
  )
}

export default AnsokanDetailsHeader
