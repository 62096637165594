import { isNil, includes } from 'ramda'
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber'
import { FormikHelpers as FormikActions, FormikValues } from 'formik'
import { trrDate, trrDateUTC } from '@local/Utils/Helpers/formatDate'
import { matchStringByRegex, UUIDRegex } from '@local/Utils/Helpers/regexes'
import { addDays, subDays } from 'date-fns'
import { format } from 'date-fns-tz'

type MapToOutput<I, U = I> = (v: I) => U

export const getAnsokanId = () =>
  matchStringByRegex(window.location.pathname, UUIDRegex)

export const formatCurrencySEK: MapToOutput<number, string> = (amount) =>
  new Intl.NumberFormat('sv-se', {
    style: 'currency',
    currency: 'SEK',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
    .format(amount)
    .replace(/,00([^\d])/g, '$1')

export const formatPercentageNumber: MapToOutput<number, string> = (number) => {
  const formattedNumber = number / 100

  return new Intl.NumberFormat('sv-se', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 20,
  }).format(formattedNumber)
}

export const isLinkAnchor = (href: string): boolean => href.startsWith('#')

export const isLinkExternal = (href: string): boolean =>
  href.startsWith('http') ||
  href.startsWith('mailto:') ||
  href.startsWith('tel:')

const linkFileFormats = `
  doc
  docx
  odt
  xls
  xlsx
  xlt
  xltx
  ods
  pdf
`
  .trim()
  .split(/\s+/g)

export const isLinkFile = (href: string): boolean =>
  linkFileFormats.filter((s) => includes(s, href.slice(-4))).length !== 0

const toDigits = (numString: string) =>
  numString.replace(/\D/g, '').split('').map(Number)

export const validateOrganizationNumber = (
  organizationNumber: string
): boolean => {
  const digits = toDigits(organizationNumber)
  const checkDigit = digits[digits.length - 1]
  const total = digits
    .slice(0, -1)
    .reverse()
    .map((current: number, index: number) =>
      index % 2 === 0 ? current * 2 : current
    )
    .map((current: number) => (current > 9 ? current - 9 : current))
    .reduce(
      (current: number, accumulator: number) => current + accumulator,
      checkDigit
    )
  return total % 10 === 0
}

export const pickNumbersFromString = (input: string): string =>
  input?.replace(/\D/g, '')

export const formatPhoneNumberSE = (number: string): string => {
  if (!isNil(number)) {
    const phoneUtilInstance = PhoneNumberUtil.getInstance()
    const _number = phoneUtilInstance.parseAndKeepRawInput(number, 'SE')
    return (
      phoneUtilInstance
        .format(_number, PhoneNumberFormat.NATIONAL)
        // add spaces between hyphons
        ?.split('-')
        ?.join(' - ')
    )
  }
  return ''
}

export const formatOrgNr = (n: string) => `${n?.slice(-10, -4)}-${n?.slice(-4)}`

export const setFieldValue =
  (
    _setFieldValue: FormikActions<FormikValues>['setFieldValue'],
    fieldName: string,
    _setFieldTouched?: FormikActions<FormikValues>['setFieldTouched']
  ) =>
  (value: unknown): void => {
    const wrappedValue = value instanceof Date ? trrDateUTC(value) : value
    _setFieldValue(fieldName, wrappedValue).catch((err) => console.log(err))
    if (_setFieldTouched) {
      _setFieldTouched(fieldName, true, false).catch((err) => console.log(err))
    }
  }

type DateFormatType = (date?: Date | number) => string

export const startDate = trrDate(new Date(1970))
export const octoberStart = trrDate(new Date('2022-10-01'))
export const today = trrDate()
export const yesterday = trrDate(subDays(today, 1))
export const tomorrow = trrDate(addDays(today, 1))

export const getDate: DateFormatType = (date: Date | number = new Date()) =>
  format(date, 'yyyy-MM-dd')

export const getDaysFromDate = (date: Date, numberOfDaysFromDate: number) =>
  date
    ? new Date(
        trrDate(
          new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate() + numberOfDaysFromDate
          )
        )
      )
    : null

export const dayBeforeEndDate = (endDate: Date) => getDaysFromDate(endDate, -1)

export const dayAfterStartDate = (startDate: Date) =>
  getDaysFromDate(startDate, 1)

export const getMaxStartDate = (endDate: Date) => {
  if (endDate) {
    return endDate <= today ? dayBeforeEndDate(endDate) : yesterday
  } else {
    return yesterday
  }
}

export const removeUnicodeGeneralPunctuationChars = (str: string) =>
  str.replace(/[\u2000-\u206F]/g, '')

export const removeHtmlTags = (str: string): string => {
  const doc = new DOMParser().parseFromString(str, 'text/html')
  return doc.body.textContent || ''
}

export const replaceEpiVariables = (
  text: string,
  variables: { [key: string]: string } = {}
) => {
  let result = text
  Object.keys(variables).forEach((key) => {
    const regex = new RegExp(`{{${key}}}`, 'g')
    result = result.replace(regex, variables[key])
  })
  return result
}
