import React from 'react'
import { IDinaAnsokningarContent } from '@local/Views/DinaAnsokningar/DinaAnsokningar.types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { Grid } from '@mui/material'
import HTMLMapper from '@local/Components/HTMLMapper'

const ForandradAnstallningssituation = (): JSX.Element => {
  const { forandradAnstallningssituation } =
    usePickEpiContent<IDinaAnsokningarContent>()

  return (
    <Grid container>
      <Grid item xs md={8}>
        <HTMLMapper body={forandradAnstallningssituation.mainBody} />
      </Grid>
    </Grid>
  )
}

export default ForandradAnstallningssituation
