import React from 'react'
import { Provider } from 'react-redux'
import DinaAnsokningar from '@local/Views/DinaAnsokningar'
import { AppShellRouter, KeyRoute } from '@local/Router'
import AnsokanDetails from '@local/Views/AnsokanDetails'
import { AppShellDataProps, AppShellDataProvider } from '@trr/app-shell-data'
import { store } from '@local/Store/configureStore'
import SuccessModal from '@local/Components/SuccessModal'
import TidigareSysselsattningar from '@local/Views/TidigareSysselsattningar'
import { createTheme, ThemeProvider } from '@mui/material'
import { defaultLocale, themeOptions } from '@trr/mui-theme'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { svSE } from '@mui/x-date-pickers/locales'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { sv } from 'date-fns/locale'

const App = ({ appShellData }: { appShellData: AppShellDataProps }) => {
  const theme = createTheme(themeOptions, defaultLocale, svSE)

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
        <Provider store={store}>
          <AppShellDataProvider value={appShellData}>
            <>
              <SuccessModal />
              <AppShellRouter currentKey={appShellData.currentKey}>
                <KeyRoute urlKey="dina-ansokningar">
                  <DinaAnsokningar />
                </KeyRoute>
                <KeyRoute urlKey="ansokan">
                  <AnsokanDetails />
                </KeyRoute>
                <KeyRoute urlKey="tidigare-sysselsattningar">
                  <TidigareSysselsattningar />
                </KeyRoute>
              </AppShellRouter>
            </>
          </AppShellDataProvider>
        </Provider>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
