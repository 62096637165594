import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAppDispatch } from '@local/Store/configureStore'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material'

import { closeModal, selectModal } from './modalSlice'

const SuccessModal = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { modalOpen, content, linkTo } = useSelector(selectModal)

  const closeModalCallback = useCallback(() => {
    linkTo && history.push(linkTo)
    dispatch(closeModal())
  }, [dispatch, history, linkTo])

  return (
    modalOpen && (
      <Dialog onClose={closeModalCallback} open={modalOpen}>
        <DialogTitle>{content.heading}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body1">{content.description}</Typography>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button variant="text" onClick={closeModalCallback}>
            {content.buttonClose}
          </Button>
        </DialogActions>
      </Dialog>
    )
  )
}

export default SuccessModal
