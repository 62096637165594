import React from 'react'
import { useFormikContext } from 'formik'
import {
  IKompletteraFormValues,
  IOrsakTillUppsagning,
  OrsakerTillUppsagning,
} from '@local/Views/TidigareSysselsattningar/KompletteraForm/KompletteraForm.types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { ITidigareSysselsattningarContent } from '@local/Views/TidigareSysselsattningar/TidigareSysselsattningar.types'
import {
  getKompletteringErrors,
  getKompletteringTouched,
} from '@local/Utils/Helpers/TidigareSysselsattningar.helpers'
import Tooltip from '@local/Components/Tooltip'
import HTMLMapper from '@local/Components/HTMLMapper'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { isNil } from 'ramda'
import FormErrorMessage from '@local/Components/FormErrorMessage'

const OrsakTillUppsagning = ({ index }: IOrsakTillUppsagning): JSX.Element => {
  const { handleChange, handleBlur, errors, touched, values } =
    useFormikContext<IKompletteraFormValues>()

  const kompletteringErrors = getKompletteringErrors(index, errors)
  const kompletteringTouched = getKompletteringTouched(index, touched)

  const {
    formular: {
      orsakTillUppsagning: orsakTillUppsagningContent,
      orsakTillUppsagningRubrik: orsakTillUppsagningRubrikContent,
      orsakTillUppsagningTooltip,
    },
  } = usePickEpiContent<ITidigareSysselsattningarContent>()

  const isError =
    kompletteringTouched.orsakTillUppsagning &&
    !isNil(kompletteringErrors.orsakTillUppsagning)

  return (
    <FormControl error={isError} variant="standard" fullWidth>
      <Tooltip
        heading={orsakTillUppsagningRubrikContent.heading}
        tooltipContent={
          <HTMLMapper body={orsakTillUppsagningTooltip.mainBody} />
        }
      />

      <RadioGroup
        onChange={handleChange}
        onBlur={handleBlur}
        defaultValue={values.kompletteringar[index].orsakTillUppsagning}
      >
        {Object.values(OrsakerTillUppsagning).map((orsak) => (
          <FormControlLabel
            key={orsak}
            value={orsak}
            control={<Radio color="primary" />}
            label={orsakTillUppsagningContent[orsak]}
            name={`kompletteringar.${index}.orsakTillUppsagning`}
            id={`kompletteringar.${index}.orsakTillUppsagning.${orsak}`}
          />
        ))}
      </RadioGroup>

      {isError && (
        <FormErrorMessage>
          {kompletteringErrors.orsakTillUppsagning}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export default OrsakTillUppsagning
