import React from 'react'
import {
  useAnsokanNeedsGranskning,
  usePickEpiContent,
} from '@local/Utils/Hooks'
import { getFormattedDatePeriod } from '@local/Utils/Helpers/Ansokan.helpers'
import { formatOrgNr } from '@local/Utils/Helpers'
import { trrFormat } from '@local/Utils/Helpers/formatDate'
import {
  IAnsokanListItem,
  IDinaAnsokningarContent,
} from '@local/Views/DinaAnsokningar/DinaAnsokningar.types'
import { Grid } from '@mui/material'
import AnsokanCard from '@local/Components/AnsokanCard'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import PeopleIcon from '@mui/icons-material/People'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import FolderList from '@local/Components/FolderList'
import StatusChip from '@local/Components/StatusChip'
import Divider from '@local/Components/Divider'
import { styled } from '@mui/material/styles'
import { Status } from '@local/Common.types'

const StyledAnsokanListItem = styled('div')(({ theme }) => ({
  '&:not(:last-child)': {
    marginBottom: theme.spacing(2),
  },
}))

const AnsokanListItem = ({ ansokan }: { ansokan: IAnsokanListItem }) => {
  const needsGranskning = useAnsokanNeedsGranskning(ansokan)
  const {
    ansokanList: content,
    status,
    dinaTrrAnsokningarLinks,
  } = usePickEpiContent<IDinaAnsokningarContent>()

  const formattedName =
    ansokan.foretag.kontaktPerson?.namn ??
    ansokan.foretag.kontaktPerson?.epostadress

  const anstallningsPeriodText = getFormattedDatePeriod(
    ansokan.anstallningsdatum,
    ansokan.sistaAnstallningsdatum,
    content.anstallningsperiodSeparator,
    content.ejFaststalltSistaDatum
  )

  return (
    <StyledAnsokanListItem>
      <AnsokanCard
        data-testid="ansokan-card"
        title={ansokan.foretag.namn}
        subtitle={formatOrgNr(ansokan.foretag.organisationsnummer)}
        action={
          <>
            {ansokan.isTvist && (
              <StatusChip status={Status.Tvist} statusContent={status} />
            )}
            <StatusChip status={ansokan.status} statusContent={status} />
          </>
        }
        href={`${dinaTrrAnsokningarLinks.ansokanUrl}${ansokan.id}`}
        buttonLinkText={
          needsGranskning ? content.granskaButton : content.aktuellButtonMobile
        }
      >
        <Grid container>
          <Grid item xs={12} sm={6} md={4}>
            <FolderList
              title={content.ansokanSkapad}
              subtitle={String(trrFormat(ansokan.skapadDatum))}
              icon={<CheckCircleIcon />}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FolderList
              title={content.anstallningsperiod}
              subtitle={anstallningsPeriodText}
              icon={<CalendarMonthIcon />}
            />
          </Grid>

          {formattedName && (
            <Grid item xs={12} sm={6} md={4}>
              <FolderList
                title={content.foretagsKontaktPerson}
                subtitle={formattedName}
                icon={<PeopleIcon />}
              />
            </Grid>
          )}
        </Grid>
        {ansokan.forsakranEjOverklagaUppsagning && (
          <>
            <Divider offset_xs={48} />

            <FolderList
              title={content.forsakran}
              subtitle={content.forsakranEjOverklagaUppsagning}
              icon={<CheckCircleOutlineIcon />}
            />
          </>
        )}
      </AnsokanCard>
    </StyledAnsokanListItem>
  )
}

export default AnsokanListItem
